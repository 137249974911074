import React from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import {
  HalfWidthBlock,
  Medailon,
  PaddingWrapper,
  ReferenceContainer,
  TitleH2,
} from "@igloonet-web/shared-ui"
import ReferenceBanner from "@igloonet-web/shared-ui/components/reference/reference-banner"
import ReferenceBannerContent from "@igloonet-web/shared-ui/components/reference/reference-banner-content"

import Logo from "../../../images/reference/smart-lashes/logo2.png"
import Top from "../../../images/reference/smart-lashes/top.png"
import Zdanovich from "../../../images/reference/smart-lashes/zdanovich.png"

const pozadi = {
  backgroundImage: `linear-gradient(rgba(24,25,26,0.9),rgba(2,3,4,0.7)),url(${Top})`,
}

const SmartLashes = () => (
  <Layout>
    <Helmet>
      <title>Díky růstu až k expanzi na západní trhy | igloonet.cz</title>
      <meta
        name="description"
        content="„Spolupráce s igloonetem nám pomohla v&nbsp;růstu tržeb i&nbsp;celého e-shopu, který by bez správně nastavených PPC kampaní nebyl tak dynamický,“ Aleksander Zdanovich, majitel."
      />
    </Helmet>

    <ReferenceBanner
      styles={pozadi}
      logo={Logo}
      alt="logo Smart Lashes"
      extodkaz="//smartlashes.cz/"
      heading="Díky kontinuálnímu růstu až k expanzi na západní trhy"
      odstavec="jaro 2017 – současnost"
    >
      <ReferenceBannerContent mainText="Smart Lashes je jedničkou na českém a slovenském trhu umělých řas a produktů s nimi spojených. Všechny své výrobky navrhují a posléze testují ve vlastních salonech, ručí tak za 100% kvalitu. Zároveň vedou kurzy a školí lash specialisty." />
    </ReferenceBanner>

    <ReferenceContainer>
      <HalfWidthBlock>
        <PaddingWrapper>
          <TitleH2>Rozsah projektu</TitleH2>
          <p className="text-center">
            Se Smart Lashes spolupracujeme od jara roku 2017, kdy jsme převzali
            výkonnostní kampaně na Googlu a Seznamu. Za prvních 6 měsíců jsme v
            Česku a na Slovensku zvýšili tržby o 120 %.
          </p>
        </PaddingWrapper>

        <Medailon
          image={Zdanovich}
          alt=""
          text="Spolupráce s igloonetem nám pomohla v růstu tržeb i celého e-shopu,
          který by bez správně nastavených PPC kampaní nebyl tak dynamický."
          name="Aleksander Zdanovich"
          position="majitel"
        />

        <PaddingWrapper>
          <p>
            Spokojený klient nám svěřil i&nbsp;správu kampaní na FB/IG a
            e&#x2011;maling, pravidelně také konzultujeme marketingovou
            i&nbsp;byznysovou stránku. Více než 4 roky tedy společně rosteme a
            celou firmu posouváme dál.
          </p>
        </PaddingWrapper>
        <PaddingWrapper>
          <TitleH2>Další postup</TitleH2>
          <p>V současné době pomáháme s expanzí do Německa a Rakouska.</p>
        </PaddingWrapper>
      </HalfWidthBlock>
    </ReferenceContainer>

    <ContactForm contact="adam" />
  </Layout>
)

export default SmartLashes
